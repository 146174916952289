import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  Banner,
  Helmet,
  Theme,
  IconPhone,
  IconAt,
  IconFacebook,
  IconTwitter,
  IconInstagram,
  IconClock,
  Variables,
  Container,
  Row,
  Box,
  Heading,
  Intro,
  routesObject,
  PreStyledComponents,
  Link,
  Announcement
} from "@life-without-barriers/react-components"
import styled from "styled-components"

import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import { ContactUsTile } from "../../components/foster-care/ContactUsTile/ContactUsTile"
import Layout from "../../components/foster-care/Layout"
import { Site, PageAnnouncement } from "../../contentTypes"

interface Props {
  location: { pathname: string }
  data: {
    headerImage: IGatsbyImageData
    site: Site
    announcement: PageAnnouncement
  }
}

const { youthPinkMedium } = Variables

const { fosterCareThemeFull } = Theme

const { IconWrapper } = PreStyledComponents

const SpacedSpan = styled.span`
  display: inline-block;
  min-width: 100px;
`

const IntroductionBox = ({ className }: { className: string }) => (
  <Intro
    className={className}
    text="Our friendly and helpful foster care specialists are ready to
        answer your questions. Whether you're looking to start the
        process or just want more info, our team can help."
  />
)

const ContactUsPage = ({
  location,
  data: {
    site: { siteMetadata },
    headerImage,
    announcement
  }
}: Props) => {
  return (
    <Layout>
      <div className="contact-us-page">
        <Helmet
          title={`Contact LWB about foster care - phone, email or social | ${siteMetadata.title}`}
          description="We are here to answer all of your foster care questions. One of our friendly team will be happy to assist and help you start your foster care journey."
          image={headerImage}
          siteUrl={siteMetadata.siteUrl}
          path={location.pathname}
        />
        <Banner
          title="Contact us"
          image={headerImage}
          breadcrumbs={[routesObject.fosterCareHome]}
        />
        <Container>
          <Row className="flex-wrap-reverse flex-wrap-l">
            <Box className="t-other-ways w-100 w-seven-twelfths-l layout-gap">
              <IntroductionBox className="dn db-l" />
              <Heading size={2} topMargin="standard">
                Other ways to contact us
              </Heading>
              <p>
                You can contact us via email and telephone. We are currently
                staffed Monday to Saturday.
              </p>
              <Row className="flex-wrap mt2 pb2">
                <Box className="w-50-l pt3">
                  <Box className="flex items-center pt3">
                    <IconWrapper size="30" color={fosterCareThemeFull.xxxlight}>
                      <IconPhone
                        height="20"
                        width="20"
                        color={fosterCareThemeFull.dark}
                      />
                    </IconWrapper>
                    <Box className="pl3">
                      <Link
                        className="black fw3 no-underline-ns f3-ns fw8-ns"
                        to="tel:1800935483"
                        ariaLabel="Phone 1 3 0 0. 5 9 2. 2 2 7"
                      >
                        1300 592 227
                      </Link>
                    </Box>
                  </Box>
                  <Box className="flex items-center pt2">
                    <IconWrapper size="30" color={fosterCareThemeFull.xxxlight}>
                      <IconAt
                        height="20"
                        width="20"
                        color={fosterCareThemeFull.dark}
                      />
                    </IconWrapper>
                    <Box className="pl3">
                      <a className="black fw4" href="mailto:carers@lwb.org.au">
                        carers@lwb.org.au
                      </a>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex w-50-l pt4 ph3 ph4-ns">
                  <IconWrapper size="30" color={fosterCareThemeFull.xxxlight}>
                    <IconClock
                      height="20"
                      width="20"
                      color={fosterCareThemeFull.dark}
                    />
                  </IconWrapper>
                  <Box className="pl3">
                    <p className="mt0 fw8">Opening hours (AEST)</p>
                    <p className="mt2">
                      <SpacedSpan>Mon - Fri</SpacedSpan>
                      8am - 8pm
                    </p>
                    <p className="mt0">
                      <SpacedSpan>Saturday</SpacedSpan>
                      9am - 5pm
                    </p>
                    <p className="mt0">
                      <SpacedSpan>Sunday</SpacedSpan>
                      Closed
                    </p>
                  </Box>
                </Box>
              </Row>
              <p>
                You can also contact us via social channels. Our team will come
                back to you as soon as possible.
              </p>
              <Row className="flex-wrap mt2">
                <Box className="w-100 w-33-l ph3-l">
                  <ContactUsTile
                    ga={{
                      eventCategory: "Facebook link",
                      eventLabel: "Contact-us"
                    }}
                    icon={<IconFacebook height="20" color={youthPinkMedium} />}
                    text="Facebook"
                    link="https://www.facebook.com/LWBAustralia"
                    externalLink
                  />
                </Box>
                <Box className="w-100 w-33-l ph3-l">
                  <ContactUsTile
                    ga={{
                      eventCategory: "Twitter link",
                      eventLabel: "Contact-us"
                    }}
                    icon={<IconTwitter height="18" color={youthPinkMedium} />}
                    text="Twitter"
                    link="https://twitter.com/LWBAustralia"
                    externalLink
                  />
                </Box>
                <Box className="w-100 w-33-l ph3-l">
                  <ContactUsTile
                    ga={{
                      eventCategory: "Instagram link",
                      eventLabel: "Contact-us"
                    }}
                    icon={<IconInstagram height="20" color={youthPinkMedium} />}
                    text="Instagram"
                    link="https://instagram.com/lwbaustralia"
                    externalLink
                  />
                </Box>
              </Row>
              <div className="pt4">
                {announcement && (
                  <Announcement insideBoxComponent={true} {...announcement} />
                )}
              </div>
            </Box>
            <Box className="w-100 w-third-l">
              <FosterCareForm formId="foster-care-contact-us" />
            </Box>
            <Box>
              <IntroductionBox className="db dn-l pb4" />
            </Box>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    headerImage: file(relativePath: { regex: "/contactus.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1440)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    announcement: contentfulAnnouncement(page: { eq: "caring-space" }) {
      ...ContentfulAnnouncement
    }
  }
`

export default ContactUsPage
